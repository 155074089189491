import type { ReactNode } from 'react';
import { Suspense } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

import styles from './Boundary.module.css';
import Spinner from './Spinner';

function ErrorFallback(props: FallbackProps) {
  const {
    error,
    // componentStack,
    resetErrorBoundary,
  } = props;

  const info = JSON.stringify(error.info, undefined, 2);

  return (
    <div role="alert" className={styles.errorMessage}>
      {info.includes('ECONNREFUSED') ? (
        <>
          <h2>Service unavailable</h2>
          <pre>
            The Human Organ Atlas is currently unavailable. We apologise for the
            inconvenience.
          </pre>
        </>
      ) : (
        <>
          <h2>Something went wrong</h2>
          <pre>
            {error.status} {error.message}
          </pre>
          <pre>{info}</pre>
        </>
      )}
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

function Boundary(props: { resetKeys: unknown[]; children: ReactNode }) {
  const { resetKeys, children } = props;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={resetKeys}>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default Boundary;
