import { useMetadataValues } from 'lib/api-hooks';

import DonorCard from './DonorCard';
import styles from './DonorList.module.css';

function DonorList() {
  const donorsUnique = useMetadataValues('SamplePatient_number');
  donorsUnique.sort();

  return (
    <div className={styles.donorContainer}>
      {donorsUnique.map((donor) => {
        return <DonorCard key={donor} donor={donor} />;
      })}
    </div>
  );
}

export default DonorList;
