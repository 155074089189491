import { formatHOAFeed } from 'lib/helpers';
import { Carousel } from 'lib/home';
import { useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';

import feed from '../rss_HOA.xml?raw';
import ModalFeed from './ModalFeed';
import styles from './RSSFeed.module.css';

export interface FeedItem {
  title: string;
  link: string;
  description: string;
  content: string;
}

function feedParser() {
  const textXML = new DOMParser().parseFromString(feed, 'text/xml');
  const parsedItems = textXML.querySelectorAll('item');
  return [...parsedItems].map((i: Element) => {
    const content = i.querySelectorAll('encoded')[0].innerHTML;
    const description = i.querySelectorAll('description')[0].innerHTML;
    const title = i.querySelectorAll('title')[0].innerHTML;
    const link = i.querySelectorAll('link')[0].innerHTML;
    return {
      title,
      link,
      description: formatHOAFeed(description),
      content: formatHOAFeed(content),
    };
  });
}

const parsedFeed = feedParser();
const nbArticles = parsedFeed.length;

function RSSFeed() {
  const [indexArticle, setIndexArticle] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  if (nbArticles === 0) {
    return null;
  }

  return (
    <Carousel
      changeSlide={() =>
        setIndexArticle((nbArticles + indexArticle - 1) % nbArticles)
      }
    >
      <div className={styles.feedWrapper}>
        <ModalFeed
          open={modalOpen}
          handleClick={() => setModalOpen(false)}
          feedItem={parsedFeed[indexArticle]}
        />
        <h2 className={styles.feedTitle}>Latest news</h2>
        <div className={styles.feedContainer}>
          <h3 className={styles.feedHeadline}>
            {parsedFeed[indexArticle].title}
          </h3>
          <a
            className={styles.inlineLink}
            href={parsedFeed[indexArticle].link}
            target="_blank"
            rel="noreferrer"
          >
            Go to article <FiExternalLink />
          </a>

          <div>
            <div
              className={styles.feedContent}
              dangerouslySetInnerHTML={{
                __html: parsedFeed[indexArticle].description || '',
              }}
            />
            <button
              className={styles.expandButton}
              type="button"
              onClick={() => setModalOpen(true)}
            >
              View more
            </button>
          </div>
        </div>
      </div>
    </Carousel>
  );
}

export default RSSFeed;
