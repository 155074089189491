import { usePageTracking, useScrollToTop } from 'lib/hooks';

import logoCZI from '../images/czi.png';
import logoCZI2x from '../images/czi_2x.png';
import logoESRF from '../images/esrf.png';
import logoESRF2x from '../images/esrf_2x.png';
import europeanFlag from '../images/Flag_of_Europe.png';
import logoUCL from '../images/ucl.png';
import logoUCL2x from '../images/ucl_2x.png';
import FAIRPrinciples from './FAIRPrinciples';
import HomeGallery from './HomeGallery';
import styles from './HomePage.module.css';
import RSSFeed from './RSSFeed';
import StatSection from './StatSection';

function HomePage() {
  useScrollToTop();
  usePageTracking();

  return (
    <div className={styles.container}>
      <h1 className={styles.headTitle}>
        Welcome to the Human&nbsp;Organ&nbsp;Atlas
      </h1>
      <div className={styles.twoColumnsSection}>
        <section>
          <p className={styles.introParagraph}>
            The Human Organ Atlas provides{' '}
            <strong>Hierarchical Phase-Contrast Tomography</strong> images of
            human anatomy from the micron to whole organ scale. Human tissue is
            inherently hierarchical and multiscale with cells, tissues and
            organs organised into specific structures at each of the levels.
          </p>
          <p>
            While techniques like histology can give us sub-cellular images,
            they are only possible on small parts of tissue, missing the tissue
            or organ scale context. On the other hand, clinical imaging like{' '}
            <abbr title="Computed Tomography">CT</abbr> and{' '}
            <abbr title="Magnetic Resonance Imaging">MRI</abbr> scans image
            whole organs and bodies, but with a resolution only down to around a
            millimetre, missing small tissues and cells.{' '}
            <abbr title="Hierarchical Phase-Contrast Tomography">HiP-CT</abbr>{' '}
            bridges these scales and does so in isotropic 3D resolution. With
            HiP-CT we can image whole intact organs with 8-20 μm/voxel, and then
            image volumes of interest in the still intact organ down to the
            single micron/voxel.
          </p>
          <p>
            This Atlas aims to give open access, to scientists and the public
            alike, to explore and reuse these datasets with the hope that the
            Atlas will act as a reference to provide new insights into our
            biological makeup in health and disease.
          </p>
          <p>
            Want to learn more about the technique, the wider project or project
            related opportunities? Visit the{' '}
            <a
              className={styles.inlineLink}
              href="https://mecheng.ucl.ac.uk/hip-ct/"
              target="_blank"
              rel="noreferrer"
            >
              project website
            </a>
            .
          </p>
        </section>
        <section>
          <StatSection />
          <RSSFeed />
        </section>
      </div>

      <div className={styles.gallerySection}>
        <HomeGallery />
      </div>

      <div className={styles.wideSection}>
        <FAIRPrinciples />
      </div>

      <div className={styles.twoColumnsSection}>
        <div>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>Team</h2>
            <ul className={styles.listTeam}>
              <li>
                <a
                  className={styles.inlineLink}
                  href="https://mecheng.ucl.ac.uk/hip-ct/"
                  target="_blank"
                  rel="noreferrer"
                >
                  UCL
                </a>
                , London, England:{' '}
                <strong>
                  Peter D Lee, Claire Walsh, David Stansby, Joseph Brunet,
                  Theresa Urban
                </strong>
              </li>
              <li>
                <a
                  className={styles.inlineLink}
                  href="http://www.esrf.eu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ESRF
                </a>
                , Grenoble, France:{' '}
                <strong>
                  Paul Tafforeau, Hector Dejea I Velardo, Joanna Purzycka,
                  Guillaume Gaisné, Axel Bocciarelli, Marjolaine Bodin
                </strong>
              </li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>Funding</h2>
            <p>
              This project has been made possible by funding from the{' '}
              <a
                className={styles.inlineLink}
                href="https://www.esrf.fr/"
                target="_blank"
                rel="noreferrer"
              >
                European Synchrotron Radiation Facility (ESRF)
              </a>
              , the{' '}
              <a
                className={styles.inlineLink}
                href="https://chanzuckerberg.com"
                target="_blank"
                rel="noreferrer"
              >
                Chan Zuckerberg Initiative
              </a>
              , a donor-advised fund of the Silicon Valley Community Foundation,
              the{' '}
              <a
                className={styles.inlineLink}
                href="https://www.ukaachen.de/kliniken-institute/institut-fuer-pathologie/register-covid-19-obduktionen/register-vorstellung/"
                target="_blank"
                rel="noreferrer"
              >
                German Registry of COVID-19 Autopsies (DeRegCOVID)
              </a>
              , supported by the German Federal Ministry of Health, The UK{' '}
              <a
                className={styles.inlineLink}
                href="https://www.ukri.org/councils/mrc/"
                target="_blank"
                rel="noreferrer"
              >
                Medical Research Council (MRC)
              </a>
              , the{' '}
              <a
                className={styles.inlineLink}
                href="https://raeng.org.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Royal Academy of Engineering
              </a>
              , and the{' '}
              <a
                className={styles.inlineLink}
                href="https://wellcome.org/"
                target="_blank"
                rel="noreferrer"
              >
                Wellcome Trust
              </a>
              .
            </p>

            <div className={styles.fundingsLogos}>
              <a
                className={styles.logoCardLink}
                href="https://mecheng.ucl.ac.uk/hip-ct/"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.logoCard}>
                  <img
                    src={logoUCL}
                    srcSet={`${logoUCL2x} 2x`}
                    alt="Logo UCL"
                    width="292"
                    height="100"
                  />
                </div>
              </a>
              <a
                className={styles.logoCardLink}
                href="https://www.esrf.fr/"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.logoCard}>
                  <img
                    src={logoESRF}
                    srcSet={`${logoESRF2x} 2x`}
                    alt="Logo ESRF"
                    width="91"
                    height="100"
                  />
                </div>
              </a>
              <a
                className={styles.logoCardLink}
                href="https://chanzuckerberg.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.logoCard}>
                  <img
                    src={logoCZI}
                    srcSet={`${logoCZI2x} 2x`}
                    alt="Logo CZI"
                    width="197"
                    height="100"
                  />
                </div>
              </a>
            </div>
          </section>
        </div>
        <div>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>Acknowledgements</h2>
            <p className={styles.acknowledgementParagraph}>
              The development of this portal has been done as part of the{' '}
              <a
                className={styles.inlineLink}
                href="https://www.panosc.eu/"
                target="_blank"
                rel="noreferrer"
              >
                PaNOSC project.
              </a>
            </p>
            <div className={styles.acknowledgementBox}>
              <img
                className={styles.europeanFlag}
                src={europeanFlag}
                alt="European flag"
              />
              <p className={styles.acknowledgementParagraph}>
                PaNOSC has received funding from the European Union’s{' '}
                <a
                  className={styles.inlineLink}
                  href="https://ec.europa.eu/programmes/horizon2020/en/what-horizon-2020"
                  target="_blank"
                  rel="noreferrer"
                >
                  Horizon 2020
                </a>{' '}
                research and innovation programme under grant agreement No.
                823852.
              </p>
            </div>
            <p>
              The following people were involved in the development: Paul
              Tafforeau, Alejandro De Maria Antolinos, Axel Bocciarelli,
              Marjolaine Bodin, Andrew Götz, Guillaume Gaisné and Simon Delcamp
              from the ESRF, Jiří Majer from ELI, as well as the broader PaNOSC
              and ICAT communities.
            </p>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>References</h2>
            <p>
              Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
              intact human organs with local resolution of cellular structures
              using hierarchical phase-contrast tomography. <em>Nat Methods</em>{' '}
              (2021).{' '}
              <a
                className={styles.inlineLink}
                href="https://doi.org/10.1038/s41592-021-01317-x"
                target="_blank"
                rel="noreferrer"
              >
                https://doi.org/10.1038/s41592-021-01317-x
              </a>
            </p>

            <p>
              <a
                className={styles.inlineLink}
                href="https://mecheng.ucl.ac.uk/HOAHub/"
                target="_blank"
                rel="noreferrer"
              >
                HOAHub
              </a>{' '}
              is the wider project that organises and manages data collection
              for the Human Organ Atlas.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
