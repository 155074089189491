import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'wouter';

export const JOIN_CHAR = '~';
export const JOIN_IN_CHAR = ';';

export const OPERATORS = {
  eq: 'eq~',
  in: 'in~',
  like: 'like~',
  glt: 'glt~',
  lteq: 'lteq~',
  gteq: 'gteq~',
};

export function useQueryParam(name: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(name)?.split(JOIN_CHAR)[1];

  const setValue = useCallback(
    (val: string) => {
      setSearchParams((prev) => {
        if (val) {
          prev.set(name, val);
        } else {
          prev.delete(name);
        }
        return prev;
      });
    },
    [name, setSearchParams],
  );

  const remove = useCallback(() => {
    setValue('');
  }, [setValue]);

  return {
    value,
    isActive: !!value,
    setValue,
    remove,
  };
}

export function useListQueryParam(name: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  // In this case, two characters are used to distinguish the information
  // The value is like: in~{value1};{value2};...
  // so to have only the list of values, we first split by '~' to separate
  // the operator from the values and get the values with the [1]
  const rawValue = searchParams.get(name)?.split(JOIN_CHAR)[1];

  const values = useMemo(
    () => (rawValue ? rawValue.split(JOIN_IN_CHAR) : []),
    [rawValue],
  );

  const setValues = useCallback(
    (vals: string[]) => {
      setSearchParams((prev) => {
        if (vals.length > 0) {
          prev.set(name, `${OPERATORS.in}${vals.join(JOIN_IN_CHAR)}`);
        } else {
          prev.delete(name);
        }
        return prev; // types don't match doc
      });
    },
    [name, setSearchParams],
  );

  const toggleValue = useCallback(
    (val: string) => {
      const list = new Set(values);
      if (list.has(val)) {
        list.delete(val);
      } else {
        list.add(val);
      }

      setValues([...list]);
    },
    [setValues, values],
  );

  const remove = useCallback(() => {
    setValues([]);
  }, [setValues]);

  return {
    values,
    isActive: values.length > 0,
    toggleValue,
    remove,
  };
}
