import { useIcatSessionId, useMultipleMetadataParams } from 'lib/api-hooks';
import { Spinner } from 'lib/boundary';
import { Suspense, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';

import Gallery from './GalleryImage';
import styles from './HomeGallery.module.css';

type ResourcesGalleryType = Record<string, string[]>;

const NAMES = {
  ids: 'ResourcesGallery',
  paths: 'ResourcesGalleryFilePaths',
};

function pickImages(resourcesGallery: ResourcesGalleryType) {
  const { ids, paths } = resourcesGallery;

  // Handle the case where there is less than 4 datasets returned
  const nbImages = Math.min(paths.length, 4);
  const randomIndices = new Set<number>();
  while (randomIndices.size < nbImages) {
    randomIndices.add(Math.floor(Math.random() * paths.length));
  }

  // The gallery file ids are stored in a single string, separated by ' '
  // The gallery file paths are stored in a single string, separated by ','
  // and only thumbnail images should be picked from the gallery,
  // otherwise, JSON or MP4 files could be picked.
  // Also, several thumbnails can be found in the gallery of a dataset
  return [...randomIndices].map((i) => {
    const splitPaths = paths[i].split(',');
    const splitIds = ids[i].split(' ');
    let indexRes;
    do {
      indexRes = Math.floor(Math.random() * splitPaths.length);
    } while (!splitPaths[indexRes].includes('/thumbnail'));
    return splitIds[indexRes];
  });
}

function HomeGallery() {
  const sessionId = useIcatSessionId();
  const resourcesGallery = useMultipleMetadataParams(
    NAMES,
  ) as ResourcesGalleryType;

  const [randomImages, setRandomImages] = useState(() => {
    return pickImages(resourcesGallery);
  });

  return (
    <div className={styles.galleryBanner}>
      <div className={styles.galleryHeader}>
        <h2 className={styles.galleryTitle}>The Atlas in images</h2>
        <button
          className={styles.refreshButton}
          type="button"
          onClick={() => {
            setRandomImages(pickImages(resourcesGallery));
          }}
        >
          <FiRefreshCw />
        </button>
      </div>
      <div className={styles.galleryContainer}>
        <div className={styles.gallery}>
          <Suspense fallback={<Spinner />}>
            {randomImages.map((id) => {
              return <Gallery key={id} sessionId={sessionId} resourceId={id} />;
            })}
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default HomeGallery;
