import { useMediaQuery } from '@react-hookz/web';
import { Boundary, NotFound404 } from 'lib/boundary';
import { assertDefined } from 'lib/guards';
import { PageWrapper } from 'lib/layout';
import { Helmet } from 'react-helmet';
import { Route, Switch, useLocation } from 'wouter';

import DatasetPage from './Dataset/DatasetPage';
import ExplorePage from './Explore/ExplorePage';
import HelpPage from './Help/HelpPage';
import HomePage from './Home/HomePage';
import Navigation from './Navigation';
// import ReconstructionsPage from './Reconstructions/ReconstructionsPage';
import SearchPage from './Search/SearchPage';

function App() {
  const isDesktop = useMediaQuery(`(min-width: 56.25em)`);

  assertDefined(isDesktop);
  const location = useLocation();

  return (
    <>
      <Helmet
        defaultTitle="Human Organ Atlas"
        titleTemplate="%s - Human Organ Atlas"
      />
      <Navigation isDesktop={isDesktop} />

      <PageWrapper>
        <Boundary resetKeys={[location]}>
          <Switch>
            <Route path="/">
              <HomePage />
            </Route>
            <Route path="/explore" nest>
              <ExplorePage />
            </Route>
            <Route path="/search">
              <SearchPage isDesktop={isDesktop} />
            </Route>
            <Route path="/datasets/:datasetId">
              {(params) => (
                <DatasetPage
                  datasetId={params.datasetId}
                  isDesktop={isDesktop}
                />
              )}
            </Route>
            {/* <Route path="/reconstructions" nest>
            <ReconstructionsPage />
          </Route> */}
            <Route path="/help" nest>
              <HelpPage isDesktop={isDesktop} />
            </Route>
            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </Boundary>
      </PageWrapper>
    </>
  );
}

export default App;
