import { useLocation } from 'wouter';

import styles from './NotFound404.module.css';

function NotFound404() {
  const [badPath] = useLocation();
  return (
    <div className={styles.errorMessage}>
      <h2 className={styles.errorTitle}>Error 404</h2>
      <pre className={styles.errorText}>
        The page "{badPath}" you are looking for doesn't exist.
      </pre>
    </div>
  );
}

export default NotFound404;
