import { format } from 'date-fns';

import type {
  Dataset,
  FileInfo,
  GalleryFile,
  Parameter,
  ProcessedDataset,
  ProcessedFileList,
} from './api-models';
import { JOIN_CHAR, JOIN_IN_CHAR, OPERATORS } from './Filters/router-utils';
import { assertEnvVar } from './guards';
import { CATALOG_UNIT } from './unit-catalog';

const GLOBUS_ROOT = import.meta.env.VITE_GLOBUS_ROOT;

const QUERY_ALIASES = {
  Keywords: [
    'DOI_users',
    'DOI_title',
    'Sample_name',
    'SamplePaleo_repository_institution',
    'SampleLocalisation_country',
    'SampleLocalisation_name',
    'SamplePaleoGeologicalTime_formation',
    'SamplePaleoGeologicalTime_period',
    'SamplePaleoGeologicalTime_epoch',
    'SamplePaleoClassification_clade1',
    'SamplePaleoClassification_clade2',
    'SamplePaleoClassification_clade3',
    'SamplePaleoClassification_clade4',
    'SamplePaleoClassification_clade5',
    'SamplePaleoClassification_clade6',
    'SamplePaleoClassification_clade7',
    'SamplePaleoClassification_species',
  ],
};

export function processDatasetResponse(
  datasetResponse: Dataset,
): ProcessedDataset {
  const parametersTmp: Parameter[] = datasetResponse.parameters || [];
  const parameters = new Map(parametersTmp.map((p) => [p.name, p]));

  // Not splitting only with " " because sometimes, several whitespaces
  // can separate two ids, so splitting with the \s+ regex
  const galleryIds =
    parameters.get('ResourcesGallery')?.value.split(/\s+/u) || [];

  const gallery: GalleryFile[] =
    parameters
      .get('ResourcesGalleryFilePaths')
      ?.value.split(',')
      .map((path, index) => ({
        id: galleryIds[index],
        name: path.slice(path.lastIndexOf('/') + 1),
        type: /\.(png|jpg)$/iu.test(path)
          ? 'image'
          : /\.(mp4)$/iu.test(path)
            ? 'video'
            : 'unknown',
      })) || [];

  return { ...datasetResponse, parameters, galleryIds, gallery };
}

export function processFileListResponse(
  excludeGallery: boolean,
  fileListResponse: FileInfo[] = [],
): ProcessedFileList {
  const filesTmp = excludeGallery
    ? fileListResponse.filter((f) => !f.Datafile.name.startsWith('/gallery/'))
    : fileListResponse;

  const files = filesTmp.map((f) => ({
    id: f.Datafile.id,
    datasetId: f.Datafile.dataset.id,
    fileSize: f.Datafile.fileSize,
    name: f.Datafile.name,
    location: f.Datafile.location,
    ext: f.Datafile.name
      .slice(f.Datafile.name.lastIndexOf('.') + 1)
      .toUpperCase(),
    shortName: f.Datafile.name.slice(1, f.Datafile.name.lastIndexOf('.')),
    meta: f.meta,
  }));

  return {
    files,
  };
}

export function getMetadataByName(
  parameters: Map<string, Parameter>,
  name: string,
): string | undefined {
  const metadata = parameters.get(name);
  if (metadata === undefined) {
    return undefined;
  }
  const value = metadata.value.toString();
  if (value === ' ') {
    return undefined;
  }

  if (metadata.name in CATALOG_UNIT) {
    return `${value} ${CATALOG_UNIT[metadata.name.toString()]}`;
  }
  return value;
}

export function formatStringDate(date: string) {
  if (date.includes('T')) {
    const [shortDate] = date.split('T');
    return shortDate;
  }
  return date;
}

export function formatDateToString(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getGlobusUrl(filePath: string) {
  assertEnvVar(GLOBUS_ROOT, 'VITE_GLOBUS_ROOT');
  const globusPath = filePath.replace(GLOBUS_ROOT, '');
  const params = new URLSearchParams({
    origin_id: import.meta.env.VITE_GLOBUS_ORIGIN_ID,
    origin_path: globusPath.slice(0, globusPath.lastIndexOf('/')),
  });

  return `https://app.globus.org/file-manager?${params.toString()}`;
}

export function sampleNameSplitter(sampleName: string) {
  const organInfo = sampleName.slice(sampleName.indexOf('_') + 1);
  const [organName, organDescription] = organInfo.split('-');
  const position = organDescription
    ? organDescription.split('_')[0]
    : undefined;
  return { organName, position };
}

export function formatOrganDescr(organ: string, position: string) {
  return isLeftRight(position) ? `${position} ${organ}` : organ;
}

export function formatOrganDescrURL(organ: string, position: string) {
  return isLeftRight(position) ? `${organ}-${position}` : organ;
}

export function isLeftRight(position: string) {
  return position === 'left' || position === 'right';
}

export function queryParamsToAPIParams(
  queryParams: Record<string, string> | undefined,
) {
  let APIParams = '';

  if (queryParams) {
    for (const [queryKey, queryValue] of Object.entries(queryParams)) {
      if (queryValue.startsWith('glt~')) {
        const range = queryValue.split(JOIN_CHAR)[1];
        const values = range.split(JOIN_IN_CHAR).map(Number);
        APIParams += `,${queryKey}~${OPERATORS.gteq}${values[0]}`;
        APIParams += `,${queryKey}~${OPERATORS.lteq}${values[1]}`;
        continue;
      }

      // Right now, we assume that an alias can't be used with any comparison
      // operator (~eq~, ~gteq~, ~lteq~ and ~glt~) because, the alias induces
      // a 'OR' condition between the different metadata behind the query.
      // Using the alias with a comparison might not work as intended, where
      // multiple comparison should be combined with an 'AND' and not with a
      // 'OR'
      if (queryKey in QUERY_ALIASES) {
        const joinedQueryParams =
          QUERY_ALIASES[queryKey as keyof typeof QUERY_ALIASES].join(';');
        APIParams += `,${joinedQueryParams}~${queryValue}`;
      } else {
        APIParams += `,${queryKey}~${queryValue}`;
      }
    }
  }
  return APIParams;
}

export function trackLink(evt: React.MouseEvent<HTMLAnchorElement>) {
  window._paq?.push(['trackLink', evt.currentTarget.href, 'link']);
}

export function trackDownload(evt: React.MouseEvent<HTMLAnchorElement>) {
  window._paq?.push(['trackLink', evt.currentTarget.href, 'download']);
}

export function trackPlay(videoSrc: string) {
  window._paq?.push(['trackEvent', 'Video', 'Play', videoSrc]);
}

export function formatHOAFeed(feed: string): string {
  return (
    feed
      // remove <![CDATA]
      .slice(9, -3)
      // remove the potential style applied to the elements in the feed
      .replaceAll(/\sstyle="[^"]*"/gu, '')
  );
}

export function rangeArray(num: number): number[] {
  const arr: number[] = [];
  for (let i = 1; i <= num; i++) {
    arr.push(i);
  }
  return arr;
}

export function scrollToTop(smooth = true) {
  window.scrollTo({ top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto' });
}
