import { useMultipleMetadataParams } from 'lib/api-hooks';
import { BiBrain } from 'react-icons/bi';
import { FiFileText, FiUsers } from 'react-icons/fi';

import StatBox from './StatBox';
import styles from './StatSection.module.css';

const NAMES = {
  listDonors: 'SamplePatient_number',
  listOrgans: 'Sample_name',
  listResources: 'ResourcesGallery',
};

function StatSection() {
  const statsMetadata = useMultipleMetadataParams(NAMES);

  const numberDonors = statsMetadata.listDonors?.length;
  const numberOrgans = statsMetadata.listOrgans?.length;
  const numberDatasets = statsMetadata.listResources?.length;

  return (
    <div className={styles.statsBanner}>
      <div className={styles.statsContainer}>
        <div className={styles.statsBoxes}>
          <StatBox
            icon={<FiUsers />}
            stat={numberDonors}
            description="donors"
          />
          <StatBox
            icon={<BiBrain />}
            stat={numberOrgans}
            description="organs"
          />
          <StatBox
            icon={<FiFileText />}
            stat={numberDatasets}
            description="datasets"
          />
        </div>
      </div>
    </div>
  );
}

export default StatSection;
