import styles from './FAIRPrinciples.module.css';

const LICENSE_NAME = import.meta.env.VITE_LICENSE_NAME;
const LICENSE_URL = import.meta.env.VITE_LICENSE_URL;

function FAIRPrinciples() {
  return (
    <>
      <h2 className={styles.sectionTitle}>FAIR principles</h2>
      <p>
        The Human Organ Atlas project is dedicated to share its results with the
        community, and to this extent, follows the FAIR principles. The FAIR
        principles are guidelines formalised by{' '}
        <a
          className={styles.inlineLink}
          href="https://doi.org/10.1038/sdata.2016.18"
          target="_blank"
          rel="noreferrer"
        >
          Wilkinson et al. (2016)
        </a>{' '}
        to help the science community to share their data in an efficient and
        easily reusable way. FAIR is an acronym for Findable, Accessible,
        Interoperable and Reusable (later also for Findable and AI-Ready).
      </p>
      <p>
        Each dataset available on this portal is described with metadata about
        the donor, the organ scanned and the scanning conditions, has a bespoke
        DOI that will redirect to the ESRF data portal and is distributed under
        the{' '}
        <a
          className={styles.inlineLink}
          href={LICENSE_URL}
          target="_blank"
          rel="noreferrer"
        >
          {LICENSE_NAME} licence
        </a>
        . To enhance FAIR data, the publications that make use of the datasets
        available on this portal <strong>must cite the DOIs</strong> of each
        dataset used.
      </p>
    </>
  );
}

export default FAIRPrinciples;
