import { useAllDatasets } from 'lib/api-hooks';
import { Media } from 'lib/dataset';
import { Link } from 'wouter';

import styles from './GalleryImage.module.css';

interface Props {
  sessionId: string;
  resourceId: string;
}

function GalleryImage(props: Props) {
  const { sessionId, resourceId } = props;

  const dataset = useAllDatasets({ ResourcesGallery: `like~${resourceId}` });

  return (
    <Link className={styles.imageContainer} to={`~/datasets/${dataset[0].id}`}>
      <Media sessionId={sessionId} type="image" id={resourceId} />
    </Link>
  );
}

export default GalleryImage;
