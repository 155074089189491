import { trackLink } from 'lib/helpers';
import { useState } from 'react';
import { FiClipboard, FiExternalLink } from 'react-icons/fi';

import styles from './DOI.module.css';

interface Props {
  doi: string;
}

function DOI(props: Props) {
  const { doi } = props;
  const [isCopied, setIsCopied] = useState(false);

  async function copyDOI() {
    await navigator.clipboard.writeText(`http://doi.org/${doi}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  }

  return (
    <div className={styles.doi}>
      <a
        className={styles.linkDOI}
        href={`http://doi.org/${doi}`}
        target="_blank"
        rel="noreferrer"
        onClick={(evt) => trackLink(evt)}
      >
        <span className={styles.badgeDOI}>DOI</span>
        <span className={styles.doiLink}>
          {doi} <FiExternalLink />
        </span>
      </a>
      <button
        className={styles.copyButton}
        type="button"
        onClick={() => void copyDOI()}
        data-copied={isCopied || undefined}
      >
        Copy DOI
        <FiClipboard />
      </button>
    </div>
  );
}

export default DOI;
