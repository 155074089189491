import User from './User';

interface Props {
  listUsers: string;
}

function Users(props: Props) {
  // The list of users should be formatted as
  // "{first-name} {first-name}, {last-name}, {ORCiD}; ..."
  // otherwise, the list of users should not have ';' in it

  const { listUsers } = props;
  const splitUsers = listUsers.split('; ');
  return (
    <span>
      {splitUsers.map((u, index) => {
        const [name, surname, orcid] = u.split(', ');

        return (
          <span key={u}>
            <User name={name} surname={surname} orcid={orcid} />
            {index + 1 === splitUsers.length ? '' : <>, </>}
          </span>
        );
      })}
    </span>
  );
}

export default Users;
