import type { ReactNode } from 'react';

import styles from './StatBox.module.css';

interface Props {
  icon: ReactNode;
  stat?: number;
  description: string;
}

function StatBox(props: Props) {
  const { icon, stat, description } = props;

  return (
    <div className={styles.statContainer}>
      <div className={styles.statIcon}>{icon}</div>
      <div className={styles.statText}>
        {stat} {description}
      </div>
    </div>
  );
}

export default StatBox;
