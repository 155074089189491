import type { ReactNode } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import styles from './Carousel.module.css';

interface Props {
  changeSlide: (position: number) => void;
  children: ReactNode;
}

function Carousel(props: Props) {
  const { changeSlide, children } = props;
  return (
    <div className={styles.carouselPlayer}>
      <button
        className={styles.carouselButton}
        type="button"
        aria-label="Previous video"
        onClick={() => changeSlide(-1)}
      >
        <FiChevronLeft />
      </button>
      {children}
      <button
        className={styles.carouselButton}
        type="button"
        aria-label="Next video"
        onClick={() => changeSlide(+1)}
      >
        <FiChevronRight />
      </button>
    </div>
  );
}

export default Carousel;
