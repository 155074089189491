import { FiExternalLink } from 'react-icons/fi';
import { Modal } from 'react-responsive-modal';

import type { FeedItem } from './RSSFeed';
import styles from './RSSFeed.module.css';

interface Props {
  open: boolean;
  feedItem: FeedItem;
  handleClick: () => void;
}

function ModalFeed(props: Props) {
  const { open, handleClick, feedItem } = props;
  return (
    <Modal center blockScroll open={open} onClose={handleClick}>
      <div className={styles.feedContainerModal}>
        <h3 className={styles.feedHeadline}>{feedItem.title}</h3>
        <a
          className={styles.inlineLink}
          href={feedItem.link}
          target="_blank"
          rel="noreferrer"
        >
          Go to article <FiExternalLink />
        </a>
        <div
          className={styles.feedContent}
          dangerouslySetInnerHTML={{
            __html: feedItem.content,
          }}
        />
      </div>
    </Modal>
  );
}

export default ModalFeed;
