import type { PaginationBack, PaginationFront } from '../api-models';

export const DEFAULT_PAGE_SIZE = 20;

export const SIZES = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export function toPaginationBack(
  paginationQuery: PaginationFront,
): PaginationBack {
  const { page, size } = paginationQuery;
  return {
    skip: (page - 1) * size,
    limit: size,
  };
}

export function querySplitter(parametersQuery: Record<string, string>) {
  const { page, size, ...rest } = parametersQuery;

  const paginationQuery = {
    page: page ? Number.parseInt(page) : 1,
    size: size ? Number.parseInt(size) : DEFAULT_PAGE_SIZE,
  };

  return { paginationQuery, otherParams: rest };
}
