import orcidLogo from '../ORCID-iD_icon_16x16.png';
import styles from './User.module.css';

interface Props {
  name: string;
  surname: string;
  orcid: string;
}

const ORCID_PATTERN = /(?:[\dA-Z]{4}-){3}[\dA-Z]{4}/u;

function User(props: Props) {
  const { name, surname, orcid } = props;
  const isOrcid = ORCID_PATTERN.test(orcid);

  return (
    <span className={styles.user}>
      {name} {surname}
      {isOrcid && (
        <a
          className={styles.orcid}
          href={`https://orcid.org/${orcid}`}
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          <img src={orcidLogo} alt="" />
        </a>
      )}
    </span>
  );
}

export default User;
